// import { useTheme } from "../context/ThemeContext";
import "../styles/components.css";
import Download from "../assets/images/download.png";
import { Link } from "react-router-dom";
import AppleLogo from "../assets/images/apple-logo.png";
const BottomDownload = () => {
  // const { activeColors } = useTheme();
  return (
    <div className="bottom_download" style={{ backgroundColor: "#14CD8A" }}>
      <div className="left">
        <text>Download and Start Making Real Memories!</text>
     <Link style={{textDecoration:"none"}} to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192">
     <button style={{ backgroundColor: "#14CD8A", color:"black" }}>
          <img src={AppleLogo} width={20} alt="apple logo" />{" "}
          <text>Download</text>
        </button></Link>
      </div>
      <div className="right">
        {/* <FadeInSection> */}
          <img src={Download} alt="download" />
        {/* </FadeInSection> */}
      </div>
    </div>
  );
};

export default BottomDownload;
