import { useState, useRef } from "react";
import { useTheme } from "../context/ThemeContext";
import "../styles/components.css";
import Logo from "../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import ThemeSwitch from "./ThemeSwitch";

const Navbar = () => {
  const { activeColors, isDarkTheme, toggleThemeMode } = useTheme();
  const [isChanged, setIsChanged] = useState(false);

  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));

  const handleClick = () => {
    setIsChanged(!isChanged);
  };

  const routes = [
    {
      title: "Home",
      to: "/",
      // Icon: BiHomeAlt2,
    },
    {
      title: "Download",
      to:  "https://apps.apple.com/us/app/troop-the-party-app/id6476594192",
      //Icon: FiSearch,
    },
    {
      title: "About",
      to: "/about",
      //Icon: IoPricetagsOutline,
    },
    {
      title: "Contact",
      to: "#",
      //Icon: PiChatCircleBold,
    },
  ];

  return (
    <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
      <div className="navbar_desktop">
        <div
          className="navbar"
          style={{
            background: activeColors.bgSecondary,
            transition: "background-color 0.3s ease",
            width: isChanged ? "100%" : "lightblue",
          }}
        >
          <img src={Logo} alt="troop logo" />
          <div style={{ marginRight: 30 }}>
            {" "}
            <Link to="/" style={{ color: activeColors.primary }}>
              Home
            </Link>
            <Link
              to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192"
              onClick={handleClick}
              style={{ color: activeColors.primary }}
            >
              Download
            </Link>
            <Link to="/about" style={{ color: activeColors.primary }}>
              About
            </Link>
            <Link to="https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6" style={{ color: activeColors.primary }}>
              Contact
            </Link>
          </div>
        </div>
      </div>

      <div  className="navbar_mobile">
        <div className="navbar_mobile_child" style={{backgroundColor:activeColors.bgSecondary}} ref={ref} >
        <div style={{display:"flex", justifyContent:"space-between",alignItems:"center",}}>
        <img className="logo" src={Logo} alt="troop logo"/>
         <div style={{display:"flex", alignItems:"center", justifyContent:"center", marginRight:5}}>
      
     
            <div style={{backgroundColor:activeColors.bgPrimary, borderRadius:100, padding:0,}}>
       <Hamburger color={activeColors.primary} toggled={isOpen} size={23} toggle={setOpen} />
       </div>
         </div>
        </div>
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className="fixed left-0 shadow-4xl right-0 top-[3.5rem] p-5 pt-0 bg-neutral-950 border-b border-b-white/20"
              >
                <ul style={{color:activeColors.primary}} className="grid gap-2">
                  {routes.map((route, idx) => {
                    // const { Icon } = route;

                    return (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        key={route.title}
                        className="w-full p-[0.08rem] rounded-xl bg-gradient-to-tr from-neutral-800 via-neutral-950 to-neutral-700"
                      >
                        <Link

                        style={{color: activeColors.primary, textDecoration:"none", margin:20}}
                          onClick={() => setOpen((prev) => !prev)}
                          className={
                            "flex items-center justify-between w-full p-5 rounded-xl bg-neutral-950"
                          }
                          to={route.to}
                        >
                          <span style={{marginBottom:20}} className="flex gap-1 text-lg">
                            {route.title}
                          </span>
                          {/* <Icon className="text-xl" /> */}
                        </Link>
                    
                      </motion.li>
                    );
                  })}
                </ul>
                <div style={{display:"flex",justifyContent:"flex-end"}}>
            <div style={{borderRadius:100, paddingTop:1, paddingBottom:-5}}>
          <ThemeSwitch
            isDarkTheme={isDarkTheme}
            toggleThemeMode={toggleThemeMode}
            activeColors={activeColors}
           
          />
            </div>
            </div>
              </motion.div>
              
            )}
         
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
