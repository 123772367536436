import { useTheme } from "../context/ThemeContext";
import "../styles/components.css";
import Logo from "../assets/images/logo.png";
import Instagram from "../assets/images/instagram.png";
// import Tiktok from "../assets/images/tiktok.png";
import { Link } from "react-router-dom";

const Footer = () => {
  const { activeColors } = useTheme();
  return (
    <div
      className="footer"
      style={{ backgroundColor: activeColors.bgSecondary, color:activeColors.primary }}
    >
      <div className="child">
        <img className="logo" src={Logo} alt="logo" />
        <div>
          {" "}
          <Link  to="https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6" style={{ color: activeColors.primary, textDecoration:"none" }}>
            Partnerships
          </ Link>
          <Link to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192"style={{ color: activeColors.primary,textDecoration:"none" }}>
            Download
          </Link>
          <Link to="/about" style={{ color: activeColors.primary, textDecoration:"none" }}>
            About
          </Link>
        </div>

        <div>
          <Link to="https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6https://www.instagram.com/troop.party?igsh=djhjem0zODY2cHh6">
          <img className="social" src={Instagram} alt="instagram" />
          </Link>
         
          {/* <img style={{marginLeft:15}} className="social" src={Tiktok} alt="tiktok" /> */}
        </div>
      </div>
      <div
        style={{ backgroundColor: activeColors.bgPrimary, width: "100%", height:5, color:activeColors.primary }}
        className="footer_line"
      ></div>
     <div style={{marginTop:10, display:"flex", justifyContent:"center"}}>
     <text>©2024 All Rights Reserved</text>
     </div>
    </div>
  );
};

export default Footer;
