const colors = {
    light: {
      primary: "#000000",
      secondary: "#14CD8A",
      bgPrimary: "#f8f8fa",
      bgSecondary: "#ffffff",
      bgLight: "#F6F6F6",
      highlight: "#1B2030",
      bgDark: "#ffffff",
    },
    dark: {
      primary: "#ffffff",
      secondary: "#49DD96",
      bgPrimary: "#121622",
      bgSecondary: "#1B2030",
      bgLight: "#1E2536",
      highlight: "#1B2030",
      bgDark: "#0A0E16",
    },
  };
  
  export { colors };
  