import React from "react";
import Landing1 from "../assets/images/landing1.png";
import Landing2 from "../assets/images/landing2.png";
import Landing3 from "../assets/images/landing3.png";
import Landing4 from "../assets/images/landing4.png";
import Shape1 from "../assets/shapes/shape1.png";
import Shape2 from "../assets/shapes/shape2.png";
import Shape3 from "../assets/shapes/shape3.png";
import Shape4 from "../assets/shapes/shape4.png";
import Shape5 from "../assets/shapes/shape5.png";
import Shape6 from "../assets/shapes/shape6.png";
import AppleLogo from "../assets/images/apple-logo.png";
import "../styles/components.css";
import { useTheme } from "../context/ThemeContext";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const { activeColors } = useTheme();
  return (
    <div>
      <div className="landing_page_dektop">
        <div
          className="landing_page_up"
          style={{ background: activeColors.bgPrimary }}
        >
          <img className="shape_1" src={Shape1} alt="shape 1" />
          <img className="shape_2" src={Shape2} alt="shape 1" />
          <img className="shape_3" src={Shape3} alt="shape 1" />
          <img className="shape_4" src={Shape4} alt="shape 1" />
          <img className="shape_5" src={Shape5} alt="shape 1" />
          <img className="shape_6" src={Shape6} alt="shape 1" />
          <img className="shape_7" src={Shape6} alt="shape 1" />

          <div className="top">
            <img src={Landing1} alt="landing page" />
            <text style={{ color: activeColors.primary }}>
              All your{" "}
              <span style={{ color: activeColors.secondary }}>friends</span> and{" "}
              <span style={{ color: activeColors.secondary }}>parties</span>
              <br></br>On one Platform
            </text>
            <img src={Landing2} alt="landing page" />
          </div>
          <div className="down">
            <img src={Landing3} alt="landing page" />
           
            <Link style={{textDecoration:"none"}} to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192">
            <button style={{ backgroundColor: activeColors.secondary, color:"black" }}>
              <img src={AppleLogo} alt="apple logo" /> <text>Download</text>
            </button>
            </Link>
           
            <img src={Landing4} style={{ marginTop: -20 }} alt="landing page" />
          </div>
        </div>
      </div>

      <div className="landing_page_mobile"  style={{ background: activeColors.bgPrimary }}>
        <div
          className="landing_page"
          style={{ background: activeColors.bgPrimary }}
        >
          <img className="shape_1" src={Shape1} alt="shape 1" />
          <img className="shape_2" src={Shape2} alt="shape 1" />
          <img className="shape_3" src={Shape3} alt="shape 1" />
          <img className="shape_4" src={Shape4} alt="shape 1" />
          <img className="shape_5" src={Shape5} alt="shape 1" />
          <img className="shape_6" src={Shape6} alt="shape 1" />
          <img className="shape_7" src={Shape6} alt="shape 1" />

          <div className="top">
            <text style={{ color: activeColors.primary }}>
              All your{" "}
              <span style={{ color: activeColors.secondary }}>friends</span> and{" "}
              <span style={{ color: activeColors.secondary }}>parties</span>
              <br></br>On one Platform
            </text>
           <div style={{display:"flex", justifyContent:"center"}}>
            <Link style={{textDecoration:"none"}} to="https://apps.apple.com/us/app/troop-the-party-app/id6476594192">
            <button style={{ backgroundColor: activeColors.secondary, marginLeft:10, color:"black" }}>
              <img style={{width:30}} src={AppleLogo} width={10} alt="apple logo" /> <text>Download</text>
            </button>
            </Link>
         
           </div>
           <div className="top_middle">
           <img style={{marginLeft:-90, marginTop:60}} src={Landing2} alt="landing page" />
          
            <img style={{marginRight:-45, marginTop:60}} src={Landing3} alt="landing page" />
           </div>
          </div>
          <div className="down">
            {/* <img src={Landing3} alt="landing page" /> */}
           
            {/* <img src={Landing4} style={{ marginTop: -20 }} alt="landing page" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
