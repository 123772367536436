import React, { createContext, useContext, useEffect, useState } from "react";
import { colors } from "../constants/themeColors";

const ThemeContext = createContext();

export const useTheme = () => {
  return useContext(ThemeContext);
};

const ThemeProvider = ({ children }) => {
  const [isDarkTheme, setIsDarkTheme] = useState(true);
  const [activeColors, setActiveColors] = useState(colors.dark); // Initialize with dark theme colors

  const toggleThemeMode = () => {
    setIsDarkTheme(prevTheme => !prevTheme);
  };

  const getThemeMode = () => {
    const themeData = localStorage.getItem("isDarkTheme");
    if (themeData) {
      setIsDarkTheme(JSON.parse(themeData));
    }
  };

  useEffect(() => {
    getThemeMode();
  }, []);

  useEffect(() => {
    setActiveColors(colors[isDarkTheme ? "dark" : "light"]);
    localStorage.setItem("isDarkTheme", JSON.stringify(isDarkTheme));
  }, [isDarkTheme]);

  const value = { isDarkTheme, activeColors, toggleThemeMode };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
};

export default ThemeProvider;