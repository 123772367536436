import { useTheme } from "../context/ThemeContext";
import ThemeSwitch from "../components/ThemeSwitch";
import Navbar from "../components/Navbar";
import LandingPage from "../components/LandingPage";
import Middle1 from "../assets/images/middle1.jpg"
import Middle2 from "../assets/images/middle2.jpg"
import Middle3 from "../assets/images/middle3.jpg"
import Middle4 from "../assets/images/middle4.jpg"


import "../styles/pages.css"
// import ProductDisplay from "../components/ProductDisplay";
import BottomDownload from "../components/BottomDownload";
import Footer from "../components/Footer";
const Home = () => {
  const { toggleThemeMode, activeColors, isDarkTheme } = useTheme();


  return (
    <div>
      <div className="progress"></div>


      <div
        style={{
          backgroundColor: activeColors.bgPrimary,
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Navbar />
        <div  className="theme_switch" style={{ position: "absolute", right: 70 }}>
          <ThemeSwitch
            isDarkTheme={isDarkTheme}
            toggleThemeMode={toggleThemeMode}
            activeColors={activeColors}
           
          />
        </div>
      </div>
      <LandingPage />
      <div className="middle_page" style={{backgroundColor:activeColors.bgSecondary, color:activeColors.primary}}>
        <div className="left">
          <h3>Improve your social life</h3>
          <div className="middle_line" style={{position:"relative", width:"100%", backgroundColor:activeColors.secondary, height:10}}/>
          <text>By actually going outside!</text>
        </div>
        <div className="right">
          <div className="top">
            <img src={Middle1} alt="middle 1"/>
            <img src={Middle2} alt="middle 2"/>
          </div>
          <div className="bottom">
            <img src={Middle3} alt="middle 3"/>
            <img src={Middle4} alt="middle 4"/>
          </div>
        </div>
      </div>
{/* <ProductDisplay/> */}
<BottomDownload/>
<Footer/>
    
    </div>
  );
};

export default Home;
